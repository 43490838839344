import React, { useEffect } from "react";

import PdfDownloadLogo from "../../images/pdf-download-logo.png";
import LogoIso from "../../images/logo-iso.jpg";

import Foto01 from "../../photos/materdei/eCeccano-01.jpg";
import Foto02 from "../../photos/materdei/eCeccano-02.jpg";

import GalleriaLogo from "../../images/galleria.jpg";

import LogoFacebook from "../../images/facebook.png";

import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import { Link } from "react-router-dom";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20magenta%2059%20ceccano+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=18&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const Materdei = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-8" align="center">
            <ResponsiveImage src={Foto01} width={800} height={500} />
          </div>
          <div className="col-sm-4 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('materdei001.label')}</h1>
            <h5>via Magenta 59 - 03342 Ceccano (FR)</h5>

            <div className="row">
              <div className="col-1" />
              <div className="col-5">
                <h5>Tel. 0775 500291</h5>
              </div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/scuola">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t('msg026.label')}
                  />
                </Link>
              </div>
            </div>
            <h5>Email ceccano@istitutosuorevisitazione.it</h5>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t('msg013.label')}</h4>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg027.label')}</h1>
            <h5 className="home-top20-j">
            {t('materdei002.label')}
            </h5>
            <h1 className="home-top20-c">{t('msg003.label')}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('materdei003.label')}</h1>
            </font>
            <div className="row" align="center">
              <div className="col">
                <ResponsiveImage src={Foto01} width={1200} height={800} />
              </div>
            </div>
            <p></p>
            <div className="row" align="center">
              <div className="col">
                <ResponsiveImage src={Foto02} width={1200} height={800} />
              </div>
            </div>
            <p></p>
          </div>

          <div className="col-sm-1" />
          <p></p>
        </div>
        <p></p>
        <div className="row home-siamodasempre">
          <div className="col-sm-3" />

          <div className="col-sm-6">
            <h1 className="home-top20-c">{t('msg004.label')}</h1>
            <h5>
            {t('msg005.label')}
            </h5>
          </div>
          <div className="col-sm-3" />
        </div>

        <div className="row home-top20-c">
          <div className="col-sm-1" />

          <div className="col-sm-5">
            <ResponsiveImage src={LogoIso} width={520} height={380} />
          </div>
          <div className="col-sm-6 home-top20-j">
            <h1>{t('msg006.label')}</h1>
            <ul>
              <li>
                <i>{t('msg007.label')}</i>
              </li>
              <li>
                <i>{t('msg008.label')}</i>
              </li>
              <li>
                <i>{t('msg009.label')}</i>
              </li>

              <li>
                <i>{t('msg010.label')}</i>
              </li>
              <li>
                <i>{t('msg011.label')}</i>
              </li>
              <li>
                <i>{t('msg012.label')}</i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Materdei;
