import React from "react";

import PdfDownloadLogo from "../../images/pdf-download-logo.png";
import Xmille from "../../images/xmille.jpg";

import Pdf1 from "../../documents/contributi.pdf";
import Pdf2 from "../../documents/5x1000_anno_finaziario_2020_2021_2022.pdf";

import "../../css/home/home.css";

import "../../i18n";
import { useTranslation } from "react-i18next";

const MyXmille = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="home-centro-h">
        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8 home-siamodasempre">
            <img className="img-responsive" src={Xmille} alt="xmille" />
          </div>
          <div className="col-lg-4" />
        </div>
        <p></p>

        <p></p>
        <hr width="700" size="2" color="#20b2aa" align="center" />
        <p></p>
        <div className="row home-siamodasempre">
          <div className="col-lg-4" />

          <div className="col-lg-1">
            <img
              height="50"
              width="50"
              className="book-passionate"
              src={PdfDownloadLogo}
              alt="team"
            />
          </div>
          <div className="col-lg-3">
            <h3>{t("msg013.label")}</h3>
          </div>
          <div className="col-lg-4" />
        </div>
        <p></p>
        <hr width="700" size="2" color="#20b2aa" align="center" />
        <p></p>
        <div className="row home-siamodasempre" align="left">
          <div className="col-3" />
          <div className="col-lg-7 interlinea-10">
            <ul type="square" align="left">
              <li>
                <a href={Pdf2}>
                  <h4>
                    Importi percepiti Anni 2020, 2021, 2022{" "}
                    <span style={{ fontSize: "12px" }}>
                      (pubblicato il 30/06/2023)
                    </span>
                  </h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-2" />
        </div>
        
        <div className="row home-siamodasempre" align="left">
          <div className="col-3" />
          <div className="col-lg-7 interlinea-10">
            <ul type="square" align="left">
              <li>
                <a href={Pdf1}>
                  <h4>
                    Importi percepiti Anni 2017, 2018, 2019{" "}
                    <span style={{ fontSize: "12px" }}>
                      (pubblicato il 30/06/2021)
                    </span>
                  </h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-2" />
        </div>
      </div>
    </>
  );
};

export default MyXmille;
